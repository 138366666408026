// export const api_url = 'http://127.0.0.1:8000/api/v1/'

// export const api_url = 'https://app-dev-01.intelipanel.com/api/v1/' //- development

export const api_url = 'https://app-stag-01.intelipanel.com/api/v1/' // - staging


// export const api_url = 'https://app-test-01.intelipanel.com/api/v1/' //- test


export const delete_icon_color = 'rgb(228, 36, 36)'